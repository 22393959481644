import React from 'react'
import PropTypes from 'prop-types'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import { Card, Row, Col } from 'antd'
import WidgetHeader from 'components/WidgetHeader/index'
import Widget from 'components/Widget/index'
import CommonService from './../../services/Common'
import { Link, useHistory } from 'react-router-dom'
import IntlMessages from '../../util/IntlMessages'

const CustomerHistory = ({ data }) => {
	const range = CommonService.getMonthlyRange(12)
	var monthlyUser = []
	range.forEach(monthRange => {
		const userCount = data.filter(item =>
			item.active && item.mobileNoVerified && item.created.dateTime >= monthRange.startsAt
			&& item.created.dateTime <= monthRange.endsAt).length
		monthlyUser.push({
			name: monthRange.month + ', ' + monthRange.year,
			count: userCount
		})
	})
	const totalUser = data.filter(item => item.active && item.mobileNoVerified).length

	return (
		<Card>
			<WidgetHeader styleName='gx-flex-row' title='Total Customer' extra={<span><Link to='/customer/list'><IntlMessages id='view.all.customer' /></Link><i
        className='gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle'/></span>}/>
			<Row>
				<Col lg={24} md={12} sm={12} xs={24}>
					<div className='ant-row-flex'>
						<h2 className='gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium'>{CommonService.setDecimalComma(totalUser)}</h2>
					</div>
				</Col>
			</Row>
			<ResponsiveContainer width='100%' height={220}>
				<AreaChart data={monthlyUser}
					margin={{ top: 10, right: 0, left: -15, bottom: 0 }}>
					<Tooltip />
					<XAxis dataKey='name' />
					<CartesianGrid strokeDasharray='3 3' />
					{/* <defs>
						<linearGradient id='color15' x1='0' y1='0' x2='0' y2='1'>
							<stop offset='5%' stopColor='#38AAE5' stopOpacity={0.8} />
							<stop offset='95%' stopColor='#F5FCFD' stopOpacity={0.8} />
						</linearGradient>
					</defs> */}
					<Area dataKey='count' strokeWidth={2} stackId='2' type='monotone' fillOpacity={1} stroke='#003366' fill='#003366' />
				</AreaChart>
			</ResponsiveContainer>
		</Card>
	)
}

export default CustomerHistory

CustomerHistory.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object)
}
