// Functions
const hasAccess = (collection, access = 'r') => {
	const admin = localStorage.getItem('admin')
	const superAdmin = localStorage.getItem('superAdmin')

	if (admin) {
		const adminRoles = {}
		var adminObj = JSON.parse(admin)
		adminObj.roles.forEach(item => {
			adminRoles[item.collectionName] = item.access
		})
		if (access === 'r') {
			if (adminRoles[collection]) {
				return true
			}
			return false
		} else if (access === 'rw') {
			if (adminRoles[collection] && adminRoles[collection] === access) {
				return true
			}
			return false
		} else {
			return false
		}
	} else if (superAdmin) {
		return true
	} else {
		return false
	}
}

export default {
	hasAccess
}
