import React from 'react'
import PropTypes from 'prop-types'
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip } from 'recharts'
import CommonService from './../../services/Common'
import WidgetHeader from 'components/WidgetHeader/index'
import { Link } from 'react-router-dom'
import IntlMessages from '../../util/IntlMessages'

const MonthlySale = ({ data }) => {
	// Process data
	const range = CommonService.getMonthlyRange(12)
	var monthlyData = []
	range.forEach(monthRange => {
		const filteredData = data.filter(item =>
			item.doc_id !== '' && item.bill.payment.dateTime >= monthRange.startsAt
			&& item.bill.payment.dateTime <= monthRange.endsAt)
		monthlyData.push({
			name: monthRange.month + ', ' + monthRange.year,
			Motor: filteredData.filter(item => item.productCode === 'MTR').length,
			Nibedita: filteredData.filter(item => item.productCode === 'NIB').length,
			PPA: filteredData.filter(item => item.productCode === 'PPA').length,
			OMC: filteredData.filter(item => item.productCode === 'B&H').length
		})
	})


	return <div className='gx-card'>
		<div className='gx-card-body'>
		<div className='gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2'>
				<WidgetHeader styleName='gx-flex-row' title='Monthly Sales' extra={<span><Link to='/policy/list'><IntlMessages id='view.all.policy' /></Link><i
					className='gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle' /></span>} />
				<ResponsiveContainer width='100%' height={185}>
					<AreaChart data={monthlyData}
						margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
						<Tooltip labelFormatter={index => monthlyData[index].name} />
						<Legend />
						<CartesianGrid horizontal={false} strokeDasharray='3 3' />
						<Area type='monotone' dataKey='Motor' fillOpacity={1} stroke='#52c41a' fill='#52c41a' />
						<Area type='monotone' dataKey='Nibedita' fillOpacity={1} stroke='#038FDE' fill='#038FDE' />
						<Area type='monotone' dataKey='PPA' fillOpacity={1} stroke='#FE9E15' fill='#FE9E15' />
						<Area type='monotone' dataKey='OMC' fillOpacity={1} stroke='#20c997' fill='#20c997' />
					</AreaChart>
				</ResponsiveContainer>
			</div>
		</div>
	</div>
}

export default MonthlySale

MonthlySale.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object)
}

