import React from 'react'
import Widget from 'components/Widget'
import LogItem from './LogItem'
import WidgetHeader from 'components/WidgetHeader/index'
import {Card, Spin} from 'antd'

const SystemLog = ({data}) => {
	return (
		<Widget>
			<WidgetHeader styleName='gx-flex-row' title='System Logs' extra={<span><Spin/></span>}/>
			
			{data.map((log, index) =>
				<LogItem key={index} data={log} />)
			}
		</Widget>
	)
}


export default SystemLog
