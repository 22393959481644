import React, { useState, useEffect } from 'react'
import { Badge, Card, Switch } from 'antd'

import RoleService from './../../services/Role'
import SystemService from './../../services/System'

const MaintenanceMode = () => {
	const [loadingMaintenance, setLoadingMaintenance] = useState(true)
	const [checkedMaintenance, setCheckedMaintenance] = useState(false)

	useEffect(() => {
		SystemService.getMaintenanceStatus().then(res => {
			if (res.state === 'success') {
				setCheckedMaintenance(res.maintenance)
				setLoadingMaintenance(false)
			}
		}, err => {
			console.error(err)
		})
	}, [])

	const setMaintenanceStatus = status => {
		setLoadingMaintenance(true)
		SystemService.setMaintenanceStatus(status).then(res => {
			if (res.state === 'success') {
				setLoadingMaintenance(false)
				setCheckedMaintenance(status)
			}
		}, err => console.error(err))
	}


	return (
		<Card title='Maintenance Mode'>
			<p className='gx-text-grey gx-mb-2'>The maintenance mode allows you to display a notice to your users while performing any maintenance task in the App.</p>
			<div style={{ marginTop: 15 }}>
				{
					RoleService.hasAccess('System', 'rw') ?
						<Switch loading={loadingMaintenance} onChange={setMaintenanceStatus} checked={checkedMaintenance} /> : null
				}
				<p style={{ marginTop: '15px' }}>
					<span style={{ marginRight: '15px' }}>
						<Badge status={checkedMaintenance ? 'danger' : 'success'}>
							<div className='head-example' style={{ marginLeft: 5 }} />
						</Badge>
					</span>
					All customer apps are currently {checkedMaintenance ? 'down' : 'active'}
				</p>
			</div>
		</Card>
	)
}

export default MaintenanceMode
