import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import { useHistory } from 'react-router-dom'

import MaintenanceMode from './MaintenanceMode.js'

import AuthService from './../../services/Auth'

const System = () => {
	const history = useHistory()

	useEffect(() => {
		// Periodic auth checking
		var authTimer = setInterval(() => {
			if (!AuthService.status()) {
				AuthService.reset()
				history.push('/signin')
			}
		}, 1000)

		return function cleanup() {
			clearInterval(authTimer)
		};
	}, [])

	return (
		<Row>
			<Col lg={12} md={12} sm={24} xs={24}>
				<MaintenanceMode />
			</Col>
			<Col lg={12} md={12} sm={24} xs={24}>
			</Col>
		</Row>
	)
}

export default System
