import React, { useState, useEffect, useRef } from 'react'
import { Col, Row } from 'antd'
import LoadingBar from 'react-top-loading-bar'
import { useHistory } from 'react-router-dom'

import SystemLog from 'components/home/SystemLog'
import MonthlySale from 'components/home/MonthlySale'
import PolicyStatistics from 'components/home/PolicyStatistics'
import TotalRevenueCard from 'components/home/TotalRevenueCard'
import CustomerHistory from 'components/home/CustomerHistory'
// import TotalCustomerCard from 'components/home/TotalCustomerCard'
// import GrowthCard from 'components/home/GrowthCard'
// import RecentActivity from 'components/dashboard/CRM/RecentActivity'
// import TicketList from 'components/dashboard/CRM/TicketList'
// import TaskByStatus from 'components/dashboard/CRM/TaskByStatus'
// import WelComeCard from 'components/home/WelComeCard'
// import Overview from 'components/dashboard/CRM/Overview'
import Auxiliary from 'util/Auxiliary'
// import Widget from 'components/Widget/index'
// import CurrencyCalculator from 'components/dashboard/Crypto/CurrencyCalculator'
// import IconWithTextCard from 'components/dashboard/CRM/IconWithTextCard'
// import { recentActivity, taskList, trafficData } from './data'

import UserService from './../../services/User'
import PolicyService from './../../services/Policy'
import LogService from './../../services/Log'
import AuthService from './../../services/Auth'
import RoleService from './../../services/Role'

const Home = () => {
	// hooks
	const [userData, setUserData] = useState([])
	const [policyData, setPolicyData] = useState([])
	const [logData, setLogData] = useState([])
	const ref = useRef(null)
	const history = useHistory()

	var userLoading = false
	var policyLoading = false

	const setLoading = (type, state) => {
		if (type === 'user') {
			// user
			userLoading = state
			if (!policyLoading) {
				state ? ref.current.continuousStart() : ref.current.complete()
			}
		} else {
			// policy
			policyLoading = state
			if (!userLoading) {
				state ? ref.current.continuousStart() : ref.current.complete()
			}
		}
	}

	// Role Checkup
	useEffect(() => {
		// Periodic auth checking
		var authTimer = setInterval(() => {
			if (!AuthService.status()) {
				AuthService.reset()
				history.push('/signin')
			}
		}, 1000)

		// Load data based on Role
		if (RoleService.hasAccess('UserProduct', 'r')) {
			setLoading('policy', true)
			PolicyService.getAllData().then(res => {
				setPolicyData(res.policies)
				setLoading('policy', false)
			}, err => {
				console.error(err)
				setLoading('policy', false)
			})
		}
		if (RoleService.hasAccess('User', 'r')) {
			setLoading('user', true)
			UserService.getAll().then(res => {
				setUserData(res.users)
				setLoading('user', false)
			}, err => {
				console.error(err)
				setLoading('user', false)
			})
		}
		if (RoleService.hasAccess('Log', 'r')) {
			var logTimer = setInterval(() => {
				LogService.getLatest().then(res => {
					setLogData(res.logs)
				}, err => {
					console.error(err)
				})
			}, 3000)
		}

		return function cleanup() {
			clearInterval(authTimer)
			if (logTimer) {
				clearInterval(logTimer)
			}
		};
	}, [])


	return (
		<Auxiliary>
			<LoadingBar height={3} color='#0C8F4D' ref={ref} />
			<Row>
				{
					policyData && policyData.length > 0 ? <Col xl={12} lg={12} md={24} sm={24} xs={24}>
						<MonthlySale data={policyData} />
					</Col> : null
				}
				{
					policyData && policyData.length > 0 ? <Col xl={12} lg={12} md={24} sm={24} xs={24}>
						<PolicyStatistics data={policyData} />
					</Col> : null
				}
				{
					policyData && policyData.length > 0 ? <Col xl={userData && userData.length > 0 ? 12 : 24} lg={userData && userData.length > 0 ? 12 : 24} md={24} sm={24} xs={24}>
						<TotalRevenueCard data={policyData} />
					</Col> : null
				}
				{
					userData && userData.length > 0 ? <Col xl={policyData && policyData.length > 0 ? 12 : 24} lg={policyData && policyData.length > 0 ? 12 : 24} md={24} sm={24} xs={24}>
						<CustomerHistory data={userData} />
					</Col> : null
				}
				{
					logData && logData.length > 0 ? <Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<SystemLog data={logData} />
					</Col> : null
				}
			</Row>
		</Auxiliary>
	)
}

export default Home
