import React from 'react'
import PropTypes from 'prop-types'
import WidgetHeader from 'components/WidgetHeader/index'
import { Link } from 'react-router-dom'
import IntlMessages from '../../util/IntlMessages'
import LineIndicator from './LineIndicator'
import { ResponsiveContainer } from 'recharts'



const PolicyStatistics = ({ data }) => {
	const filteredData = data.filter(item => item.doc_id !== '')
	const percentage = {
		mtr: (filteredData.filter(item => item.productCode === 'MTR').length / filteredData.length * 100).toFixed(2) + '%',
		nib: (filteredData.filter(item => item.productCode === 'NIB').length / filteredData.length * 100).toFixed(2) + '%',
		omc: (filteredData.filter(item => item.productCode === 'B&H').length / filteredData.length * 100).toFixed(2) + '%',
		ppa: (filteredData.filter(item => item.productCode === 'PPA').length / filteredData.length * 100).toFixed(2) + '%'
	}
	return (
		<div className='gx-card'>
			<div className='gx-card-body'>
				<div className='gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2'>
					<WidgetHeader styleName='gx-flex-row' title='Policy Statistics' extra={<span><Link to='/policy/list'><IntlMessages id='view.all.policy' /></Link><i
						className='gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle' /></span>} />
					<ResponsiveContainer width='100%' height={185}>
						<ul className='gx-line-indicator'>
							<li>
								<LineIndicator width={percentage.mtr} title='Motor' color='green' value={percentage.mtr} />
							</li>
							<li>
								<LineIndicator width={percentage.nib} title='Nibedita' color='blue' value={percentage.nib} />
							</li>
							<li>
								<LineIndicator width={percentage.ppa} title='People Personal Accident' color='orange' value={percentage.ppa} />
							</li>
							<li>
								<LineIndicator width={percentage.omc} title='Overseas Mediclaim' color='teal' value={percentage.omc} />
							</li>
						</ul>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	)
}
export default PolicyStatistics

PolicyStatistics.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object)
}
