import React, {memo, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import {ConfigProvider} from 'antd'
import {IntlProvider} from 'react-intl'

import AppLocale from 'lngProvider'
import MainApp from './MainApp'
import SignIn from '../SignIn'
import ResetPassword from '../ResetPassword'
import VerifyAdminEmail from '../VerifyAdminEmail'
import VerifyUserEmail from '../VerifyUserEmail'
// import {setInitUrl} from 'appRedux/actions/Auth'
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from 'appRedux/actions/Setting'

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from '../../constants/ThemeSetting'

import ClockService from './../../services/Clock'

const RestrictedRoute = ({component: Component, location, /*authUser,*/ ...rest}) =>
  <Route
    {...rest}
    render={props => {
			const adminToken = localStorage.getItem('adminKey')
			const adminTokenExp = parseInt(localStorage.getItem('adminKeyExp'))
			const superAdminToken = localStorage.getItem('superAdminKey')
			const superAdminTokenExp = parseInt(localStorage.getItem('superAdminKeyExp'))
			var status = undefined
			if (adminToken) {
				if (adminTokenExp > ClockService.now()) {
					// admin verified
					status = 'admin'
				}
			} else if (superAdminToken) {
				if (superAdminTokenExp > ClockService.now()) {
					// super admin verified
					status = 'superAdmin'
				}
			}


      return status === 'admin' || status === 'superAdmin'
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: location}
          }}
				/>
		}}
  />


const App = (props) => {

  const dispatch = useDispatch()
  const {locale, themeType, navStyle, layoutType, themeColor} = useSelector(({settings}) => settings)
  const {/*authUser, */initURL} = useSelector(({auth}) => auth)

  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  useEffect(() => {
    let link = document.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = `/css/${themeColor}.css`  //This line is changed, this comment is for explaination purpose.

    link.className = 'gx-style'
    document.body.appendChild(link)
  },[])


  useEffect(() => {
    // if (initURL === '') {
    //   // dispatch(setInitUrl(location.pathname))
    // }
    const params = new URLSearchParams(location.search)

    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')))
    }
    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')))
    }
    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')))
    }
    setLayoutType(layoutType)
    setNavStyle(navStyle)
  })


  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('framed-layout')
      document.body.classList.add('full-layout')
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout')
      document.body.classList.remove('framed-layout')
      document.body.classList.add('boxed-layout')
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('full-layout')
      document.body.classList.add('framed-layout')
    }
  }

  const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll')
      document.body.classList.add('horizontal-layout')
    } else {
      document.body.classList.remove('full-scroll')
      document.body.classList.remove('horizontal-layout')
    }
  }

  useEffect(() => {
		const adminToken = localStorage.getItem('adminKey')
		const adminTokenExp = parseInt(localStorage.getItem('adminKeyExp'))
		const superAdminToken = localStorage.getItem('superAdminKey')
		const superAdminTokenExp = parseInt(localStorage.getItem('superAdminKeyExp'))

		console.log(location.pathname)

		var status = undefined
    if (location.pathname === '/') {
			if (adminToken) {
				if (adminTokenExp > ClockService.now()) {
					// admin verified
					status = 'admin'
				}
			} else if (superAdminToken) {
				if (superAdminTokenExp > ClockService.now()) {
					// super admin verified
					status = 'superAdmin'
				}
			}



      if (status !== 'admin' && status !== 'superAdmin') {
        history.push('/signin')
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        history.push('/home')
      } else {
        history.push(initURL)
      }
    }
  }, [/*authUser, */initURL, location, history])

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      console.log('adding dark class')
      document.body.classList.add('dark-theme')
      document.body.classList.add('dark-theme')
      let link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.href = '/css/dark_theme.css'
      link.className = 'style_dark_theme'
      document.body.appendChild(link)
    }}
  ,[])

  const currentAppLocale = AppLocale[locale.locale]

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>

        <Switch>
          <Route exact path='/signin' component={SignIn}/>
          <Route exact path='/resetpassword' component={ResetPassword}/>
					<Route exact path='/verify/admin/email/:token' component={VerifyAdminEmail}/>
          <Route exact path='/verify/user/email/:token' component={VerifyUserEmail}/>
					<RestrictedRoute path={`${match.url}`}
						// authUser={authUser}
						location={location} component={MainApp}/>
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default memo(App)
