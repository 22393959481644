import React from 'react' 
import ClockService from './Clock'

// Functions
const months = [
	'Jan', 'Feb', 'Mar', 'Apr',
	'May', 'Jun', 'Jul', 'Aug',
	'Sep', 'Oct', 'Nov', 'Dec'
]

const nextMonth = (month, year) => {
	month++
	if (month > 11) {
		month = 0
		year++
	}
	return {
		month, year,
		startsAt: ClockService.thatDay((new Date(year, month, 1)).getTime())
	}
}

const prevMonth = (month, year) => {
	month--
	if (month < 0) {
		month = 11
		year--
	}
	
	return {
		month, year,
		startsAt: ClockService.thatDay((new Date(year, month, 1)).getTime())
	}
}


const getMonthlyRange = (totalMonths = 12) => {
	const today = new Date()

	var range = []
	var currentMonth = today.getMonth()
	var currentYear = today.getFullYear()
	while (range.length < totalMonths) {
		let nextMonthObj = nextMonth(currentMonth, currentYear)
		let thisMonthObj = prevMonth(nextMonthObj.month, nextMonthObj.year)
		let prevMonthObj = prevMonth(thisMonthObj.month, thisMonthObj.year)
		range.push({
			month: months[currentMonth],
			year: currentYear.toString(),
			startsAt: thisMonthObj.startsAt,
			endsAt: nextMonthObj.startsAt - 1
		})
		currentMonth = prevMonthObj.month
		currentYear = prevMonthObj.year
	}
	return range.reverse()
}

const setDecimalComma = num => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
// learn more, https://blog.abelotech.com/posts/number-currency-formatting-javascript/

function isDev(){ 
	return '_self' in React.createElement('div');
}

export default {
	getMonthlyRange, setDecimalComma, isDev
}
