const productData = [
  {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'Alarm Clock',
    variant: 'Gold ',
    mrp: '$990 ',
    price: '$699 ',
    offer: '29 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 5,
    description: 'Horo is a home grown brand with utmost emphasis on quality goods to users... ',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'Bizinto 1 Three Pin',
    variant: 'White',
    mrp: '$490 ',
    price: '$399 ',
    offer: '29 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 4,
    description: 'Bizinto is an indirectly manufacture of Power strip in Delhi and supplying...',
  }, {
    thumb: 'https://via.placeholder.com/600X338',
    name: 'Samons Flameless',
    variant: 'Black',
    mrp: '$49 ',
    price: '$39 ',
    offer: '30 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.3,
    description: 'Now light your cigarette buds with ease by using this USB Rechargeable...',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'Sony MDR-ZX110',
    variant: 'White',
    mrp: '$29 ',
    price: '$15 ',
    offer: '49 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.5,
    description: 'Experience great sound quality with weight and foldable headphones...',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'iPhone 7',
    variant: 'Black,500Htz',
    mrp: '$400 ',
    price: '$359 ',
    offer: '49 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 4.2,
    description: 'Bluetooth speaker, Karaoke singing, Car Stereo, instrument recording etc... •',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'Stonx v2.1',
    variant: 'Black',
    mrp: '$29 ',
    price: '$15 ',
    offer: '49 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.1,
    description: '1 Bluetooth Dongle, 1 Aux Cable, 1 Usb Cable, 1 Manual...',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'T-Shirts',
    variant: 'White',
    mrp: '$10 ',
    price: '$5 ',
    offer: '50 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.1,
    description: '1 Bluetooth Dongle, 1 Aux Cable, 1 Usb Cable, 1 Manual...',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'Led',
    variant: 'Gold ',
    mrp: '$10 ',
    price: '$20 ',
    offer: '50%',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 5,
    description: 'Horo is a home grown brand with emphasis on quality goods to our users... ',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'football',
    variant: 'Black',
    mrp: '$490 ',
    price: '$399 ',
    offer: '29 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 4,
    description: 'Bizinto is an indirectly manufacture of Power strip in Delhi and supplying in all over india...',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'wach',
    variant: 'Black',
    mrp: '$49 ',
    price: '$39 ',
    offer: '30 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.3,
    description: 'Now light your cigarette buds with ease by using this USB Rechargeable Electronic Flameless Lighter.',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'fan',
    variant: 'White,full speed',
    mrp: '$29 ',
    price: '$15 ',
    offer: '49 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.5,
    description: 'Experience great sound quality with these light weight and fordable headphones.',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'Padraig Q7 Handheld',
    variant: 'Black,500Htz',
    mrp: '$56 ',
    price: '$35 ',
    offer: '49 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 4.2,
    description: 'Bluetooth speaker, Karaoke singing, Car Stereo, instrument recording, interviews, podcasting, etc. •',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'speaker Car Bluetooth ',
    variant: 'Black',
    mrp: '$29 ',
    price: '$15 ',
    offer: '49 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.1,
    description: '1 Bluetooth Dongle, 1 Aux Cable, 1 Usb Cable, 1 Manual',
  }, {
    thumb: 'https://via.placeholder.com/600X450',
    name: 'Running Shoes',
    variant: 'Black',
    mrp: '$10 ',
    price: '$5 ',
    offer: '50 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.1,
    description: '1 Bluetooth Dongle, 1 Aux Cable, 1 Usb Cable, 1 Manual',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'Alarm Clock',
    variant: 'Gold ',
    mrp: '$990 ',
    price: '$699 ',
    offer: '29 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 5,
    description: 'Horo is a home grown brand with utmost emphasis on quality goods ',
  }, {
    thumb: 'https://via.placeholder.com/600X400',
    name: 'Bizinto 1 Three Pin',
    variant: 'White',
    mrp: '$490 ',
    price: '$399 ',
    offer: '29 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 4,
    description: 'Bizinto is an indirectly manufacture of Power strip in Delhi and supplying...',
  },
]

export default productData
