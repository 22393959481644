import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import LoadingBar from 'react-top-loading-bar'
import IntlMessages from 'util/IntlMessages'
import AuthService from './../services/Auth'

const VerifyUserEmail = () => {
	const [verifyStatus, setVerifyStatus] = useState('loading')
	const { token } = useParams()
	const ref = useRef(null)

	useEffect(() => {
		ref.current.continuousStart()
		AuthService.verifyUserEmailLinkData(token.toString()).then(res => {
			ref.current.complete()
			setVerifyStatus(res.state)
			console.log(res)
		}, err => {
			ref.current.complete()
			console.error(err)
		})
	}, [])

	console.log(token)

	return (
		<div className='gx-login-container'>
			<LoadingBar height={3} color='#0C8F4D' ref={ref} />
			<div className='gx-login-content'>

				<div className='gx-login-header'>
					<img src={require('assets/images/logo-white.png')} alt='GDIC' title='GDIC' />
				</div>
				{ verifyStatus === 'loading' ? <div className='gx-mb-4'>
					<h2><IntlMessages id='app.userAuth.verifyUserEmail.Verifying' /></h2>
				</div> : null }
				{ verifyStatus === 'failure' ? <div className='gx-mb-4'>
					<h2><IntlMessages id='app.userAuth.verifyUserEmail.Failure' /></h2>
					<p><IntlMessages id='app.userAuth.verifyUserEmail.Failure.message' /></p>
				</div> : null }
				{ verifyStatus === 'success' ? <div className='gx-mb-4'>
					<h2 className='text-danger'><IntlMessages id='app.userAuth.verifyUserEmail.Success' /></h2>
					<p><IntlMessages id='app.userAuth.verifyUserEmail.Success.message' /></p>
					<p><IntlMessages id='app.userAuth.verifyUserEmail.Success.close' /></p>
				</div> : null }
			</div>
		</div>
	)
}

export default VerifyUserEmail
