import React from 'react'
import { Avatar, Popover } from 'antd'
import { useHistory } from 'react-router-dom'
import AuthService from './../../services/Auth'

const UserInfo = () => {
	const history = useHistory()

	const userMenuOptions = (
		<ul className='gx-user-popover'>
			{/* <li>My Account</li>
			<li>Connections</li> */}
			<li onClick={() => {
				AuthService.logout()
				history.push('/signin')
			}}>Logout
      </li>
		</ul>
	)

	return (
		<Popover overlayClassName='gx-popover-horizantal' placement='bottomRight' content={userMenuOptions}
			trigger='click'>
			<Avatar src={'https://apps.green-delta.com/img/admin/admin.png'}
				className='gx-avatar gx-pointer' alt='' />
		</Popover>
	)

}

export default UserInfo
