import React from 'react'
import {Avatar, Checkbox, Tag, Tooltip} from 'antd'

import Aux from 'util/Auxiliary'

const getTagColor = action =>
	action === 'read' ? 'geekblue' :
	action === 'create' ? 'green' :
	action === 'update' ? 'purple' : 'red'

const getMemberColor = type =>
	type === 'User' ? 'blue' :
	type === 'Admin' ? 'volcano' :
	type === 'SuperAdmin' ? 'magenta' : 'cyan'

const getDate = millis =>
	(new Date(millis)).toDateString() + ' ' + (new Date(millis)).toLocaleTimeString()

const LogItem = ({data}) => {

  return (
    <Aux>
      <div style={{
				borderTop: '1px solid #ccc',
				paddingTop: '15px'
			}} className='gx-media gx-task-list-item gx-flex-nowrap'>
        <div className='gx-media-body gx-task-item-content'>
          <div className='gx-task-item-content-left'>
            <p className='gx-text-truncate gx-mb-0'>{data.details}</p>
            <p className='gx-text-truncate gx-mb-0'>{data.created.member_id}</p>
            <p className='gx-text-truncate gx-mb-0'>{data.appName}</p>
          </div>
          <div className='gx-task-item-content-right'>
            {/* <Avatar className='gx-ml-sm-3 gx-size-30 gx-order-sm-3' src={user.avatar}/> */}
            <Tag style={{minWidth: '90px'}} color={getMemberColor(data.created.memberType)}>{data.created.memberType}</Tag>
            <Tag style={{minWidth: '65px'}} color={getTagColor(data.action)}>{data.action.toUpperCase()}</Tag>
            {/* <ul className='gx-dot-list gx-mb-0 gx-order-sm-1 gx-ml-2'>
              {getTags(tags)}
            </ul> */}
            <Tag style={{minWidth: '100px'}} color='orange'>{data.collectionName}</Tag>
            <span style={{minWidth: '180px'}} className='gx-fs-sm gx-text-grey gx-ml-3 gx-task-date gx-order-sm-4'>{getDate(data.created.dateTime)}</span>
          </div>
        </div>
      </div>
    </Aux>
  )
}

export default LogItem
