import React from 'react'
import PropTypes from 'prop-types'
import { Bar, BarChart, ResponsiveContainer, Tooltip, Legend, XAxis, CartesianGrid, YAxis } from 'recharts'
import { Card, Button, Col, Row } from 'antd'
import CommonService from './../../services/Common'
import { Select } from 'antd'
import LineIndicator from './LineIndicator'
import WidgetHeader from 'components/WidgetHeader/index'

import Widget from 'components/Widget/index'
import Metrics from 'components/Metrics'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import IntlMessages from '../../util/IntlMessages'

const TotalRevenueCard = ({ data, width }) => {
	const totalRevenue = data.filter(item =>
		item.doc_id !== '').reduce((total, policy) =>
			total + parseInt(policy.productParams.find(item =>
				item.paramName === 'Total_Premium').paramValue), 0)

	const range = CommonService.getMonthlyRange(24)
	var monthlyRevenueData = []
	range.forEach((monthRange, index) => {
		const filteredData = data.filter(item =>
			item.doc_id !== '' && item.bill.payment.dateTime >= monthRange.startsAt
			&& item.bill.payment.dateTime <= monthRange.endsAt)

		if (index < 12) {
			monthlyRevenueData.push({
				month: monthRange.month,
				prevYear: monthRange.year,
				Previous: filteredData.reduce((total, policy) =>
					total + parseInt(policy.productParams.find(item =>
						item.paramName === 'Total_Premium').paramValue), 0)
			})
		} else {
			var revIndex = index - 12
			monthlyRevenueData[revIndex].nextYear = monthRange.year
			monthlyRevenueData[revIndex].Current = filteredData.reduce((total, policy) =>
				total + parseInt(policy.productParams.find(item =>
					item.paramName === 'Total_Premium').paramValue), 0)
		}
	})

	return (
		<Card>
			<WidgetHeader styleName='gx-flex-row' title='Total Revenue' extra={<span><Link to='/policy/list'><IntlMessages id='view.all.policy' /></Link><i
        className='gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle'/></span>}/>
			<Row>
				<Col lg={24} md={12} sm={12} xs={24}>
					<div className='ant-row-flex'>
						<h2 className='gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium'>৳ {CommonService.setDecimalComma(totalRevenue)}</h2>
					</div>
				</Col>
			</Row>
			{/* <div className='gx-ml-auto'>
          <Select className='gx-mb-2 gx-select-sm' defaultValue='10' onChange={handleChange}>
            <Option value='10'>Last 10 days</Option>
            <Option value='20'>Last 20 days</Option>
            <Option value='30'>Last 30 days</Option>
          </Select>
        </div> */}
			<ResponsiveContainer width='100%' height={220}>
				{/* <BarChart data={monthlyRevenueData}
							margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
							<Tooltip labelFormatter={index => monthlyRevenueData[index].month}
								formatter={(value, name, props) => [value, name === 'Previous' ? props.payload.prevYear : props.payload.nextYear]}
							/>
							<Legend />
							<Bar dataKey='Previous' stackId='a' fill='#038FDE' barSize={width <= 575 ? 4 : 8} />
							<Bar dataKey='Current' stackId='a' fill='#FE9E15' barSize={width <= 575 ? 4 : 8} />
						</BarChart> */}
				<BarChart data={monthlyRevenueData}
					margin={{ top: 10, right: 0, left: -15, bottom: 0 }}>
					<XAxis dataKey='month' />
					<CartesianGrid strokeDasharray='3 3' />
					<Tooltip labelFormatter={index => index}
						formatter={(value, name, props) => [value, name === 'Previous' ? props.payload.prevYear : props.payload.nextYear]}
					/>
					{/* <Legend /> */}
					<Bar dataKey='Previous' fill='#003366' />
					<Bar dataKey='Current' fill='#FE9E15' />
				</BarChart>
			</ResponsiveContainer>


		</Card>
	)
}

const mapStateToProps = ({ settings }) => {
	const { width } = settings
	return { width }
}
export default connect(mapStateToProps)(TotalRevenueCard)

TotalRevenueCard.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object)
}
